import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React from "react";
import contato from "../../assets/images/contato.jpg";
import axios from "../../axios";
import CustomMessage from "../CustomMessage/index";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      color: theme.palette.primary.contrastText,
      textAlign: "center",
      marginBottom: theme.spacing(2),
    },
    subTitle: {
      color: theme.palette.primary.dark,
      textAlign: "left",
      marginBottom: theme.spacing(2),
    },
    textField: {
      margin: theme.spacing(2),
      width: "95%",
    },
    image: {
      maxWidth: "64%",
      height: "auto",
      padding: theme.spacing(1),
    },
    contact: {
      width: "auto",
      margin: "0 20px auto",
      height: "400px",
      maxWidth: "100%",
      [theme.breakpoints.down("md")]: {
        height: "auto",
        width: "100%",
      },
    },
    form: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
    },
    wrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    wrapperColumn: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    img: {
      width: "62%",
      height: "auto",
    },
    paper: {
      maxWidth: "100%",
      margin: `0px ${theme.spacing(2)}px`,
      [theme.breakpoints.down("md")]: {
        marginBottom: theme.spacing(2),
      },
    },
    title: {
      color: theme.palette.primary.main,
      paddingLeft: theme.spacing(2),
    },
    div: {
      display: "flex",
      justifyContent: "center",
    },
  })
);

export default function Contact() {
  const classes = useStyles();
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  const [error, setError] = React.useState(null);

  function handleName(event) {
    setName(event.target.value);
  }
  function handleEmail(event) {
    setEmail(event.target.value);
  }
  function handleSubject(event) {
    setSubject(event.target.value);
  }
  function handleMessage(event) {
    setMessage(event.target.value);
  }
  function handleSuccess(message) {
    setSuccess(message);
  }
  function handleError(message) {
    setError(message);
  }
  function handleCloseError() {
    setError(false);
  }
  function handleCloseSuccess() {
    setSuccess(false);
  }

  async function handleSubmitForm(event) {
    event.preventDefault();
    setSuccess(null);
    setError(null);
    if (name === "" || email === "" || subject === "" || message === "") {
      handleError("Preencha todos os campos!");
      return;
    }
    const data = {
      name,
      email,
      subject,
      message,
    };
    setLoading(true);
    try {
      const response = axios.post("/api/sendEmail.php", data);
      console.log(response);
      handleSuccess("E-mail enviado !");
      setLoading(false);
    } catch (error) {
      handleError("Erro ao enviar o email !");
      setLoading(false);
    }
  }
  return (
    <div>
      {success && (
        <CustomMessage
          open={success ? true : false}
          handleClose={handleCloseSuccess}
          type="success"
          message={success}
        />
      )}
      {error && (
        <CustomMessage
          open={error ? true : false}
          handleClose={handleCloseError}
          type="error"
          message={error}
        />
      )}
      <Paper elevation={2} className={classes.paper}>
        <div className={classes.form}>
          <Typography className={classes.title} variant="h6">
            Entre em contato
          </Typography>
          <div className={classes.div}>
            <img className={classes.contact} src={contato} alt="Contato" />
          </div>
          <TextField
            label="Seu Nome"
            required
            className={classes.textField}
            value={name}
            onChange={(event) => handleName(event)}
            margin="normal"
          />
          <TextField
            label="Seu Email"
            required
            className={classes.textField}
            value={email}
            onChange={(event) => handleEmail(event)}
            margin="normal"
          />
          <TextField
            label="Assunto"
            required
            className={classes.textField}
            value={subject}
            placeholder="Ex: Cotação Seguro Auto"
            onChange={(event) => handleSubject(event)}
            margin="normal"
          />
          <TextField
            label="Mensagem"
            required
            className={classes.textField}
            value={message}
            multiline
            onChange={(event) => handleMessage(event)}
            margin="normal"
          />
          <Button
            disabled={loading}
            onClick={(event) => handleSubmitForm(event)}
            color="primary"
            variant="contained"
            size="large"
          >
            Enviar
          </Button>
        </div>
      </Paper>
    </div>
  );
}
